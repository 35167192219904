import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Client } from './client.types';

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    routeParams: any;
    client: Client;
    subscriptions: any[];
    rewards: any[];
    // payments: Payments;
    program: any;
    onClientChanged: BehaviorSubject<any>;
    onSubscriptionsChanged: BehaviorSubject<any>;
    onSubscriptionStatusChanged: Subject<any>;
    params: any;
    paramsKoalaPro: any;
    private currentUserSubject: BehaviorSubject<User>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param router
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router
    ) {
        // Set the defaults
        this.onClientChanged = new BehaviorSubject({});
        this.onSubscriptionsChanged = new BehaviorSubject({});

        this.params = new HttpParams()
            .set('_format', 'json')
            .set('cache', 'true');

        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('user'))
        );
        this.onSubscriptionStatusChanged = new Subject();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        // console.log('ClientService - resolve');
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([this.getClient(), this.getSubscriptions()]).then(
                () => {
                    this.onSubscriptionStatusChanged.subscribe(
                        (subscriptions) => {
                            this.getSubscriptions();
                        }
                    );

                    resolve(null);
                },
                reject
            );
        });
    }

    /**
     * Get client
     *
     * @returns {Promise<any>}
     */
    getClient(): Promise<any> {
        // console.log('ClientService - getClient');
        const user = JSON.parse(localStorage.getItem('user'));
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.apiUrl}/enutri/client/` +
                        user.clientProfileId,
                    { params: this.params }
                )
                .subscribe({
                    next: (response: any) => {
                        this.client = response;
                        this.onClientChanged.next(this.client);
                        resolve(this.client);
                    },
                    error: (error) => {
                        console.error(error);
                        // this.router.navigate(['/users/auth/login']);
                        reject(error);
                    },
                });
        });
    }

    /**
     * Update client
     *
     * @param client
     * @returns {Promise<any>}
     */
    updateClient(client): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    `${environment.apiUrl}/enutri/client/` + client.clientId,
                    { ...client },
                    { params: this.params }
                )
                .subscribe((response) => {
                    this.getClient();
                    resolve(response);
                }, reject);
        });
    }

    /**
     * GET client data from KoalaPro
     *
     * @param client
     * @returns {Promise<any>}
     */
    addKoalaProClientInfo(client) {
        //client.email = 'shapovalova.kateryna@gmail.com'
        //client.email = 'christobaur@hotmail.fr'
        //client.email = 'chriskbaur@gmail.com'
        //client.email = 'rthiv026@uottawa.ca'
        //client.email = 'gcouture@equipenutrition.ca'
        this.paramsKoalaPro = new HttpParams().set(
            'client_email',
            client.email
        );
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.koalaProBackendUrl}/users/`, {
                    params: this.paramsKoalaPro,
                })
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * GET client weight data from KoalaPro
     *
     * @param client
     * @returns {Promise<any>}
     */
    addKoalaProWeight(client) {
        this.paramsKoalaPro = new HttpParams().set(
            'start_date',
            '2024-01-01 00:00:01'
        );
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.koalaProBackendUrl}/weight/reports`, {
                    params: this.paramsKoalaPro,
                })
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * Get client subscriptions
     *
     * @returns {Promise<any>}
     */
    getSubscriptions(): Promise<any> {
        // console.log('getSubscriptions()');
        const user = JSON.parse(localStorage.getItem('user'));
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.apiUrl}/enutri/subscription/all`, {
                    params: this.params,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .subscribe(
                    (response: any) => {
                        this.subscriptions = response;
                        this.onSubscriptionsChanged.next(this.subscriptions);
                        resolve(this.subscriptions);
                    },
                    (error) => {
                        console.error(error);
                        // this.router.navigate(['/users/auth/login']);
                    },
                    reject
                );
        });
    }

    /**
     * Cancel a subscription in Drupal (and Stripe if necessary).
     *
     * @param subscriptionId
     */
    cancelSubscription(subscriptionId, comment): Promise<any> {
        const routeParams = new HttpParams().set('_format', 'json');
        return new Promise((resolve, reject) => {
            const subscriptionData = {
                sub_id: subscriptionId,
                next_state: 'planned_to_unsubscribe',
                comment: comment,
            };
            this._httpClient
                .patch(
                    `${environment.apiUrl}/enutri/subscription/` +
                        subscriptionId,
                    { ...subscriptionData },
                    { params: routeParams }
                )
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Request a client retention option for a subscription.
     *
     * @param subscriptionId
     * @param option
     */
    requestRetention(subscriptionId, option): Promise<any> {
        const routeParams = new HttpParams().set('_format', 'json');
        return new Promise((resolve, reject) => {
            const retentionData = {
                sub_id: subscriptionId,
                option: option,
            };
            this._httpClient
                .post(
                    `${environment.apiUrl}/enutri/retention`,
                    { ...retentionData },
                    { params: routeParams }
                )
                .subscribe((response) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Add notification suggestion
     *
     * @param notification
     * @returns {Promise<any>}
     */
    addNotification(notification): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(
                    `${environment.apiUrl}/enutri/notification?`,
                    notification,
                    { params: { _format: 'json' } }
                )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get client rewards
     *
     * @returns {Promise<any>}
     */
    getRewards(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.apiUrl}/first_promoter/promoter`, {
                    params: this.params,
                })
                .subscribe(
                    (response: any) => {
                        this.rewards = response;
                        resolve(this.rewards);
                    },
                    (error) => {
                        console.error(error);
                    },
                    reject
                );
        });
    }
}
